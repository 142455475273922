import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Card from 'components/Card'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Pagination from 'components/Pagination';

const PostDisplay = ({ id, sources, numberOfItems, numberOfRows, order, numberOfColumns }) => {
    const [items, setItems] = useState([]);
    const [showScroll, toggleShowScroll] = useState(false);
    const [scroll, setScroll] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(numberOfItems);
    const [currentPage, setPage] = useState(1);

    const isGridDisplay = parseInt(numberOfRows) > 1;
    const itemsPerPage = Math.ceil(numberOfRows * (numberOfColumns || 4));

    // Construct URL
    let urlParams = ""
    sources.forEach((source) => {
        urlParams += `sources[]=${source}&`;
    })
    urlParams += `number_of_items=${numberOfItems}&order=${order}`
    if (isGridDisplay) {
        urlParams += `&items_per_page=${itemsPerPage}&page=${currentPage}`
    }

    // Call API and set state
    const getItem = () => {
        axios.get(`/api/v1/item_display.json?${urlParams}`)
            .then((res) => {
                setItems(res.data.items);
                setTotalItemsCount(res.data.total_count);
            });
    }
    useEffect(getItem, [sources, numberOfItems, order, itemsPerPage, currentPage]);

    // Handle scroll
    const navRef = useRef();
    const scrollLeft = () => {
        let pos = navRef.current.scrollLeft;
        pos -= navRef.current.clientWidth;
        setScroll(pos);
        navRef.current.scrollTo({left: pos, behavior: 'smooth' });
    }
    const scrollRight = () => {
        let pos = navRef.current.scrollLeft;
        pos += navRef.current.clientWidth;
        setScroll(pos);
        navRef.current.scrollTo({left: pos, behavior: 'smooth' });
    }
    
    // Set maximum scroll
    useEffect(() => {
        setMaxScroll(navRef.current.scrollWidth - navRef.current.clientWidth);
        window.addEventListener("resize", () => {
            setMaxScroll(navRef.current.scrollWidth - navRef.current.clientWidth);
        });
    });

    // Toggle scroll buttons display
    useEffect(() => {
         toggleShowScroll(items.length > numberOfColumns)
    }, [items, numberOfColumns]);

    // Reset current page
    useEffect(() => {
        setPage(1);
   }, [itemsPerPage, numberOfItems]);

    const sanitizeRows = (rows) => {
        switch(rows) {
            case '2': return '2';
            case '3': return '3';
            default: return '4';
        }
    }

    const handlePageNumberChange = (page) => {
        setPage(page)
    };

    return (
        <div className="page-block" id={id}>
            <div className={`post-display post-scroll ${isGridDisplay ? 'post-grid' : ''}`}>
                { !isGridDisplay && (
                    <>
                        <div className={`scroll-left ${ showScroll ? '': 'd-none' } ${scroll - 50 <= 0 ? 'inactive': ''}`}
                            onClick={() => scrollLeft()}>
                            <ChevronLeftIcon />
                        </div>
                        <div className={`scroll-right ${ showScroll ? '': 'd-none' } ${scroll + 50 >= maxScroll ? 'inactive': ''}`}
                            onClick={() => scrollRight()}>
                            <ChevronRightIcon />
                        </div>
                    </>
                )}

                <div className={`${isGridDisplay ? 'grid-display' : ''} display-${sanitizeRows(numberOfColumns)}`} ref={navRef}>
                    { items.map((item, idx) => {
                        return ( <Card key={idx}
                                        category={item.category}
                                        title={item.title}
                                        image={item.image}
                                        url={item.url}
                                        description={item.description}
                                        type={item.type}
                                        badge={item.badge}
                                        isExclusive={item.is_exclusive} /> )
                    })}
                </div>

                { (isGridDisplay && items.length > 0) && (
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalCount={totalItemsCount}
                        currentPage={currentPage}
                        setCurrentPage={handlePageNumberChange}
                    />
                )}
            </div>
        </div>
    );
}

export default PostDisplay